import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bd6f7ef8 = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _6eeabea4 = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _7e5b5b9a = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _6be81421 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _e1825748 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d5296c2 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _7d132d44 = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _25fef772 = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _6403e32c = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _3e0581dc = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _d46c41f2 = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _712a5bb6 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _10f97f70 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _c594831e = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _31bb498e = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _bfce13e8 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _27142aa6 = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _0b303c94 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _fd4bca24 = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _26cde125 = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _770f4e78 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _4ef75a55 = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _0464177a = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _f91d0d86 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _611131b4 = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _eb24caa4 = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _9dc21e9a = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _72c8b78e = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _72d6cf0f = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _40b720b2 = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _9696e788 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _25bec9e9 = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _16e3eb84 = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _c4a8c752 = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _1d75369a = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _48553470 = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _7d3f49e6 = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _7fedf596 = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _0a4cfec0 = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _f7e264d6 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _a217472e = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _32cdd5bb = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _039aa759 = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _2ed06678 = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _a376c680 = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _4d49142a = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _3d57c4f5 = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _d77a72e0 = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _19fca344 = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _26ebccf6 = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _082cbd30 = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _cfbcdf78 = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _039ea720 = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _19493fe0 = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _7a5775b6 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _734450af = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _95ddaea6 = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _208b278a = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _7d098a8e = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _6dfe8f6e = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _9037bd62 = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _545dffa0 = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _b4da1e04 = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _3910339e = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _5ab72272 = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _39e8b2c6 = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _258f8e48 = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _41ca4591 = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _0bcb6159 = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _007cf6fc = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _74cb5627 = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _13c73146 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _081eab30 = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _49626cca = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _bd6f7ef8,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _6eeabea4,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _7e5b5b9a,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _6be81421,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _e1825748,
    name: "index___en"
  }, {
    path: "/events",
    component: _1d5296c2,
    name: "events___de"
  }, {
    path: "/files",
    component: _7d132d44,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _25fef772,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _6403e32c,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _3e0581dc,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _d46c41f2,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _712a5bb6,
    name: "login___de"
  }, {
    path: "/logout",
    component: _10f97f70,
    name: "logout___de"
  }, {
    path: "/media",
    component: _c594831e,
    name: "media___de"
  }, {
    path: "/orders",
    component: _31bb498e,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _bfce13e8,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _27142aa6,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _0b303c94,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _fd4bca24,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _26cde125,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _770f4e78,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _4ef75a55,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _0464177a,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _f91d0d86,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _611131b4,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _eb24caa4,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _9dc21e9a,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _72c8b78e,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _72d6cf0f,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _bd6f7ef8,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _6eeabea4,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _7e5b5b9a,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _6be81421,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _1d5296c2,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _7d132d44,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _25fef772,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _6403e32c,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _3e0581dc,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _d46c41f2,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _712a5bb6,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _10f97f70,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _c594831e,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _31bb498e,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _bfce13e8,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _27142aa6,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _0b303c94,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _40b720b2,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _9696e788,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _25bec9e9,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _16e3eb84,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _c4a8c752,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _1d75369a,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _48553470,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _7d3f49e6,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _7fedf596,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _0a4cfec0,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _f7e264d6,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _a217472e,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _fd4bca24,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _26cde125,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _770f4e78,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _4ef75a55,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _0464177a,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _f91d0d86,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _611131b4,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _eb24caa4,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _9dc21e9a,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _72c8b78e,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _72d6cf0f,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _40b720b2,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _9696e788,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _25bec9e9,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _16e3eb84,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _c4a8c752,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _1d75369a,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _48553470,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _7d3f49e6,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _7fedf596,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _0a4cfec0,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _f7e264d6,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _a217472e,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _32cdd5bb,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _039aa759,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _2ed06678,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _32cdd5bb,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _039aa759,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _2ed06678,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _a376c680,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _4d49142a,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _3d57c4f5,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _d77a72e0,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _19fca344,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _26ebccf6,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _082cbd30,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _cfbcdf78,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _039ea720,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _19493fe0,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _7a5775b6,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _734450af,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _a376c680,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _95ddaea6,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _208b278a,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _4d49142a,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _7d098a8e,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _6dfe8f6e,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _9037bd62,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _545dffa0,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _b4da1e04,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _3910339e,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _5ab72272,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _39e8b2c6,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _258f8e48,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _41ca4591,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _3d57c4f5,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _d77a72e0,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _19fca344,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _26ebccf6,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _082cbd30,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _cfbcdf78,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _039ea720,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _19493fe0,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _7a5775b6,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _734450af,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _0bcb6159,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _007cf6fc,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _74cb5627,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _13c73146,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _95ddaea6,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _208b278a,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _081eab30,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _49626cca,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _7d098a8e,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _6dfe8f6e,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _9037bd62,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _545dffa0,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _b4da1e04,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _3910339e,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _5ab72272,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _39e8b2c6,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _258f8e48,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _41ca4591,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _0bcb6159,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _007cf6fc,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _74cb5627,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _13c73146,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _081eab30,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _49626cca,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _e1825748,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
